<template>
  <div class="kakao-login" v-if="isAdmin">
    <h1>
      {{ token }}
    </h1>
    <a href="#" class="mt20" @click.stop="showKakaoLoginModal">
      <img src="//k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg" width="200" />
    </a>
    <a-button class="mt20" @click.stop="requestLogoutKakao">
      <a-icon type="pause-circle" class="a-icon-size" />
      <span>Logout</span>
    </a-button>
    <a-button class="mt20" @click.stop="requestUnlinkKakao">
      <a-icon type="pause-circle" class="a-icon-size" />
      <span>Unlink Kakao</span>
    </a-button>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    isAdmin: Boolean,
  },

  computed: {
    ...mapState('auth', ['token']),
    ...mapGetters('auth', ['isLogin']),
  },
  watch: {
    isLogin() {
      if (this.isLogin) {
        this.hideKakaoLoginModal()
        this.$router.push({ name: 'Home' })
      }
    },
  },
  created() {
    if (!this.isAdmin) {
      this.showKakaoLoginModal()
    }
  },
  methods: {
    ...mapMutations('auth', ['showKakaoLoginModal', 'hideKakaoLoginModal']),
    ...mapActions('auth', ['requestLogoutKakao', 'requestUnlinkKakao']),
  },
}
</script>

<style lang="scss" scoped>
.kakao-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 300px;
  margin: 100px auto;
}
</style>
