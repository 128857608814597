<template>
  <Account :isAdmin="false" />
</template>
<script>
import Account from '@/components/Account.vue'
export default {
  components: {
    Account,
  },
}
</script>
